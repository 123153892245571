:root {
    --button-bg: #fff;
    --button-c: #fff;
    --button-border: #fff;
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    margin: 0;
    font-size: 1rem;
    border: none;
    background: none;
    font-weight: bold;
    box-shadow: none;
    border-radius: 8px;
    color: #222;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        color: inherit;
    }
}

.statusTag {
    outline: none;
    color: inherit;
    text-align: center;
}

.buttonGroup {
    display: flex;
    align-items: flex-start;
}

.buttonGroupInline {
    display: inline-flex;
    align-items: flex-start;
}

.buttonGroup div {
    width: 100%;
    display: flex;

    &.d-block {
        display: block;
    }
}

.btn:active,
.btn.active {
    box-shadow: none;
}

.btn:first-child {
    margin-left: 0;
}

.btn-discussionTeams,
.btn-discussionTeams:active,
.btn-discussionTeams:hover,
.btn-discussionTeams:visited,
.btn-discussionTeams:focus,
.btn-discussionTeams:active:hover,
.btn-discussionTeams:active:focus,
.btn-discussionTeams.active,
.btn-discussionTeams.active:hover,
.btn-discussionTeams.active:focus,
.btn-discussionTeams.active.focus {
    border: solid 1px #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #fff;
}

.btn-default,
.btn-default:active,
.btn-default:hover,
.btn-default:visited,
.btn-default:focus,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus {
    color: #222;
    background-color: #ffffff;
    border: solid 2px #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

.gradient.btn-default,
.gradient.btn-default:active,
.gradient.btn-default:hover,
.gradient.btn-default:visited,
.gradient.btn-default:focus,
.gradient.btn-default:active:hover,
.gradient.btn-default:active:focus,
.gradient.btn-default.active,
.gradient.btn-default.active:hover,
.gradient.btn-default.active:focus,
.gradient.btn-default.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: solid 1px #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    font-weight: bold;
}

.gradient.btn-default-border-primary,
.gradient.btn-default-border-primary:active,
.gradient.btn-default-border-primary:hover,
.gradient.btn-default-border-primary:visited,
.gradient.btn-default-border-primary:focus,
.gradient.btn-default-border-primary:active:hover,
.gradient.btn-default-border-primary:active:focus,
.gradient.btn-default-border-primary.active,
.gradient.btn-default-border-primary.active:hover,
.gradient.btn-default-border-primary.active:focus,
.gradient.btn-default-border-primary.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: solid 1px #0071be;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    color: #0071be;
    font-weight: bold;
}

.gradient.btn-default-border-warning,
.gradient.btn-default-border-warning:active,
.gradient.btn-default-border-warning:hover,
.gradient.btn-default-border-warning:visited,
.gradient.btn-default-border-warning:focus,
.gradient.btn-default-border-warning:active:hover,
.gradient.btn-default-border-warning:active:focus,
.gradient.btn-default-border-warning.active,
.gradient.btn-default-border-warning.active:hover,
.gradient.btn-default-border-warning.active:focus,
.gradient.btn-default-border-warning.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: solid 1px #ac631b;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    color: #ac631b;
    font-weight: bold;
}

.gradient.btn-default i {
    color: #717171;
}

.gradient.btn-default i.fontBlack {
    color: #222;
}

.gradient.btn-default.faded,
.gradient.btn-default.faded:active,
.gradient.btn-default.faded:hover,
.gradient.btn-default.faded:visited,
.gradient.btn-default.faded:focus,
.gradient.btn-default.faded:active:hover,
.gradient.btn-default.faded:active:focus,
.gradient.btn-default.faded.active,
.gradient.btn-default.faded.active:hover,
.gradient.btn-default.faded.active:focus,
.gradient.btn-default.faded.active.focus {
    color: #717171;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: solid 1px #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    font-weight: bold;
}

.btn-default:hover.resetBtn {
    background-color: #ac631b;
    color: #fff;
}

.btn-default:hover.deleteBtn {
    background: #ca3333;
    color: #fff;
}

.btn-default:hover.primaryHover {
    background: #0071be;
    color: #fff;
}

.btn-primary,
.btn-secondary {
    --button-bg: #0071be;
    --button-border: #0071be;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #1941a6;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #93c4f9;
        border-color: #93c4f9;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus,
    &.active,
    &.active:focus {
        background-color: #1941a6;
        border: 2px solid #1941a6;
        color: #ffffff;
    }
}

.btn-success {
    --button-bg: #3c7858;
    --button-border: #3c7858;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #00582f;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #a5c9b7;
        border-color: #a5c9b7;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #00582f;
        border: 2px solid #00582f;
        color: #ffffff;
    }
}

.btn-danger {
    --button-bg: #ca3333;
    --button-border: #ca3333;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #99001c;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #e6aeae;
        border-color: #e6aeae;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #99001c;
        border: 2px solid #99001c;
        color: #ffffff;
    }
}

.btn-outline-danger {
    --button-bg: #ffffff;
    --button-border: #ca3333;
    --button-c: #ca3333;

    background-color: #ffffff;
    border: 2px solid var(--button-border);
    color: var(--button-c);

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #99001c;
        border-color: #99001c;
        color: #fff;

        .fa-bookmark {
            color: #fff;
        }
    }

    &.in-progress {
        background-color: #6c6c6c;
        border-color: var(--button-border);
        color: var(--button-c);
    }

    &:focus-visible,
    &:focus {
        background-color: #ffffff;
        border-color: var(--button-border);
        color: var(--button-c);
    }

    &:active,
    &:active:focus {
        background-color: #99001c;
        border: 2px solid #99001c;
        color: #fff;
    }
}

.btn-warning {
    --button-bg: #ac631b;
    --button-border: #ac631b;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #e3c1a0;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #e6aeae;
        border-color: #e6aeae;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #934800;
        border: 2px solid #934800;
        color: #ffffff;
    }
}

.btn-outline-primary {
    --button-bg: #ffffff;
    --button-border: #0071be;
    --button-c: #0071be;

    background-color: #ffffff;
    border: 2px solid var(--button-border);
    color: var(--button-c);

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: var(--button-c);
        border-color: var(--button-border);
        color: #fff;
    }

    &.in-progress {
        background-color: #6c6c6c;
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: #ffffff;
        border-color: var(--button-border);
        color: var(--button-c);
    }

    &:active,
    &:active:focus {
        background-color: #1941a6;
        border: 2px solid #1941a6;
        color: #ffffff;
    }
}

.btn {
    &.disabled,
    &[disabled],
    &[aria-disabled='true'] {
        background-color: #6c6c6c;
        box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
        opacity: 1;
        border: 2px solid #6c6c6c;
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;
        background-image: none;

        i {
            color: #ffffff;

            &.fa-eye-slash.disabledIcon {
                color: #6d6c6c;
            }
        }

        &.hasToolTip {
            pointer-events: inherit;
        }

        &:hover,
        &:focus {
            background-color: #6c6c6c;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
            opacity: 1;
            border: 2px solid #6c6c6c;
            color: #fff;
            pointer-events: none;
            cursor: not-allowed;
            background-image: none;

            i {
                color: #ffffff;

                &.fa-eye-slash.disabledIcon {
                    color: #6d6c6c;
                }
            }

            &.hasToolTip {
                pointer-events: inherit;
            }
        }

        .btn {
            background-color: #6c6c6c;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
            opacity: 1;
            border: 2px solid #6c6c6c;
            color: #fff;
            pointer-events: none;
            cursor: not-allowed;
            background-image: none;

            i {
                color: #ffffff;

                &.fa-eye-slash.disabledIcon {
                    color: #6d6c6c;
                }
            }

            &.hasToolTip {
                pointer-events: inherit;
            }

            &:hover,
            &:focus {
                background-color: #6c6c6c;
                box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
                opacity: 1;
                border: 2px solid #6c6c6c;
                color: #fff;
                pointer-events: none;
                cursor: not-allowed;
                background-image: none;

                i {
                    color: #ffffff;

                    &.fa-eye-slash.disabledIcon {
                        color: #6d6c6c;
                    }
                }

                &.hasToolTip {
                    pointer-events: inherit;
                }
            }
        }
    }
}

.btn-bs-secondary,
.btn-bs-secondary:focus,
.btn-bs-secondary:active,
.btn-bs-secondary:visited,
.btn-bs-secondary:hover,
.btn-bs-secondary:active:hover,
.btn-bs-secondary:active:focus {
    background: #6c757d;
    border: 1px solid #6c757d;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #fff;
}

.statusTagDanger,
.statusTagDanger:hover,
.statusTagDanger:focus,
.statusTagDanger.focus {
    background: #fff;
    border: 1px solid #ca3333;
    color: #ca3333;
    border-radius: 8px;
}

.statusTagDarkDanger,
.statusTagDarkDanger:hover,
.statusTagDarkDanger:focus,
.statusTagDarkDanger.focus {
    background: #fff;
    border: 1px solid #a52a2a;
    color: #a52a2a;
    border-radius: 8px;
}

.statusTagPrimary,
.statusTagPrimary:hover,
.statusTagPrimary:focus,
.statusTagPrimary.focus {
    background: #fff;
    border: 1px solid #0071be;
    color: #0071be;
    border-radius: 8px;
}

.statusTagWarning,
.statusTagWarning:hover,
.statusTagWarning:focus,
.statusTagWarning.focus {
    background: #fff;
    border: 1px solid #ff9900;
    color: #ff9900;
    border-radius: 8px;
}

.statusTagLightWarning,
.statusTagLightWarning:hover,
.statusTagLightWarning:focus,
.statusTagLightWarning.focus {
    background: #fff;
    border: 1px solid #894812;
    color: #894812;
    border-radius: 3px;
}

.statusTagLightWarning i {
    font-size: 0.75em;
    color: #717171;
}

.statusTagSuccess,
.statusTagSuccess:hover,
.statusTagSuccess:focus,
.statusTagSuccess.focus {
    background: #fff;
    border: 1px solid #3c7858;
    color: #3c7858;
    border-radius: 8px;
}

.statusTagDarkSuccess,
.statusTagDarkSuccess:hover,
.statusTagDarkSuccess:focus,
.statusTagDarkSuccess.focus {
    background: #fff;
    border: 1px solid #497970;
    color: #497970;
    border-radius: 8px;
    justify-content: center;
}

.statusTag-bs-dark,
.statusTag-bs-dark:hover,
.statusTag-bs-dark:focus,
.statusTag-bs-dark.focus {
    background: #fff;
    border: 1px solid #343a40;
    color: #343a40;
    border-radius: 8px;
}

.statusTag-bs-secondary,
.statusTag-bs-secondary:hover,
.statusTag-bs-secondary:focus,
.statusTag-bs-secondary.focus {
    background: #fff;
    border: 1px solid #6c757d;
    color: #6c757d;
    border-radius: 8px;
}

.gradient-default,
.gradient-default:hover,
.gradient-default:focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

.label-default {
    background-color: #f2f2f2;
    color: #717171;
    font-weight: normal;
}

.label-success {
    background: rgba(15, 155, 129, 0.1);
    color: #3c7858;
    font-weight: normal;
}

.btn-outline-default {
    --button-bg: #ffffff;
    --button-border: #3c3c43;
    --button-c: #3c3c43;

    background-color: #ffffff;
    border: 2px solid #3c3c43;
    color: #3c3c43;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #3c3c43;
        border-color: #3c3c43;
        color: #fff;

        .fa-bookmark {
            color: #fff;
        }
    }

    &.in-progress {
        background-color: #6c6c6c;
        border-color: #3c3c43;
        color: #fff;
    }

    &:focus-visible,
    &:focus {
        background-color: #ffffff;
        border-color: #3c3c43;
        color: #3c3c43;
    }

    &:active,
    &:active:focus {
        background-color: #4b6a94;
        border: 2px solid #4b6a94;
        color: #fff;
    }
}

.btn-outline-success {
    border: 2px solid;
    background-color: #fff;
    color: #3c7858;
    border-color: #3c7858;

    &.instructor {
        color: #3c3c4c;
        border-color: #3c7858;

        i {
            color: #3c3c4c;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: #3c7858;
        color: #fff;

        &.instructor {
            background-color: #3c7858;
        }

        i {
            color: #fff;
        }
    }
}

.btn-outline-info {
    background-color: transparent;
    color: $btn-info-bg !important;
    border-color: $btn-info-bg;
    &:hover,
    &:active,
    &:focus {
        background-color: $btn-info-bg;
        border-color: $btn-info-border;
        color: #fff !important;
    }
}

.btn-outline-warning {
    border: 1px solid;
    background-color: transparent;
    color: $btn-warning-bg !important;
    border-color: $btn-warning-bg;
    &:hover,
    &:active,
    &:focus {
        background-color: $btn-warning-bg;
        border-color: $btn-warning-border;
        color: #fff !important;
    }
}

.btn-outline-dark {
    border: 1px solid;
    background-color: #fff;
    color: #222;
    border-color: #3c3c43;
    &:hover,
    &:active,
    &:focus {
        background-color: #3c3c43;
        border-color: #3c3c43;
        color: #fff;
    }
}

.creation .flex .btn {
    padding: 25px;
    width: 275px;
    font-size: 1em;
}

.creation,
.creationMethod {
    .flex {
        i {
            color: #717171;
            font-size: 1.5em;

            &.important {
                color: #ca3333;
            }
        }

        .fontSize16 {
            font-size: 0.88rem;
        }
    }
}

.creation .flex i.fa-check-circle,
.creationMethod .flex i.fa-check-circle {
    font-size: 1.125em;
}

.creation .flex .btn div:nth-child(2),
.creationMethod .flex .btn div:nth-child(2) {
    margin-top: 10px;
}

.creation .buttonGroup {
    margin-top: 30px;
}

.creation,
.creationMethod {
    div {
        &:first-child {
            & > .btn.btn-default {
                &:focus-visible,
                &:focus,
                &:active,
                &:active:focus,
                &:hover,
                &:focus:hover,
                &.activated {
                    background-color: #ffffff;
                    border-color: #0071be;
                    color: #0071be;

                    i {
                        color: #0071be;
                    }
                }
            }
        }
    }
}

.creation div:first-child > .btn .fa-check-circle,
.creationMethod div:first-child > .btn .fa-check-circle {
    color: #0071be;
}

.swal-custom-cancel-button {
    background-color: white !important;
    color: #222 !important;
}

.mce-btn button {
    padding: 2px !important;
}

.btn-empty,
.btn-empty:active,
.btn-empty:hover,
.btn-empty:visited,
.btn-empty:focus,
.btn-empty:active:hover,
.btn-empty:active:focus,
.btn-empty.active,
.btn-empty.active:hover,
.btn-empty.active:focus,
.btn-empty.active.focus {
    box-shadow: none;
    background: #fff;
    color: #222;
    padding: 0 !important;
}

button,
.btn,
a {
    &:focus,
    &:active:focus,
    &.active:focus {
        outline: 2px solid #222222;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }

    &:focus-visible,
    &:active:focus-visible,
    &.active:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.btn-empty-border-none,
.btn-empty-border-none:active,
.btn-empty-border-none:hover,
.btn-empty-border-none:visited,
.btn-empty-border-none:focus,
.btn-empty-border-none:active:hover,
.btn-empty-border-none:active:focus,
.btn-empty-border-none.active,
.btn-empty-border-none.active:hover,
.btn-empty-border-none.active:focus,
.btn-empty-border-none.active.focus,
.btn-empty-border-none:disabled,
.btn-empty-border-none.disabled,
.btn-empty-border-none.disabled:hover,
.btn-empty-border-none.disabled:focus,
.btn-empty-border-none[disabled],
.btn-empty-border-none[disabled]:hover {
    box-shadow: none;
    background: #fff;
    color: #222;
    padding: 0 !important;
    border: none;
}

.tab-buttons {
    display: flex;
    line-height: 1;

    .button {
        border: 1px solid #d8d8d8;
        background-color: #f6f8fa;
        color: #717171;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        padding: 10px 0;

        &:hover {
            cursor: pointer;
        }

        &.active {
            border: 1px solid #0071be;
            background-color: #ffffff;
            color: #222222;

            &:hover {
                cursor: default;
            }
        }
    }

    div:first-child {
        border-radius: 3px 0 0 3px;
    }

    div:last-child {
        border-radius: 0 3px 3px 0;
    }

    div:only-child {
        border-radius: 3px;
    }
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
    color: #1071bc;
}

.isStudent,
.test {
    @media (min-width: 992px) {
        table {
            button {
                min-width: 100px;

                &.minWidthAuto {
                    min-width: auto;
                }

                &.minWidthInherit {
                    min-width: inherit;
                }
            }
        }
    }
}

button {
    padding: 5.21px 12px;
    border-radius: 8px;
    height: inherit;
    line-height: 1.2 !important;
    transition: all 300ms ease-in-out;

    &.isEnabled {
        background-size: 500px;
        background-repeat: no-repeat;

        color: var(--button-c);
        border-color: var(--button-border);
        background-position: 0%;
        background-image: -webkit-linear-gradient(30deg, var(--button-bg) 50%, var(--button-bg) 50%);
        background-image: linear-gradient(30deg, var(--button-bg) 50%, var(--button-bg) 50%);
        transition: background 300ms ease-in-out;
        animation: enabledEffect 0.8s ease-in-out;
    }

    &:disabled,
    &.disabled,
    &[aria-disabled='true'] {
        background-color: #6c6c6c;
        border-color: #6c6c6c;
        color: #fff;
        box-shadow: none;
        outline: none;
        i {
            color: inherit;
        }

        &.isLoading {
            background-image: none;
            background-color: #a5c9b7;
            border-color: #a5c9b7;
            color: #fff;
            box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
        }

        &.isSaved {
            background-color: #3c7858;
            border-color: #3c7858;
            color: #fff;
            box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
            border-radius: 100px;
        }
    }

    &:focus-visible,
    &:focus,
    &:focus:hover {
        &:disabled,
        &.disabled,
        &[aria-disabled='true'] {
            background-color: #6c6c6c;
            border-color: #6c6c6c;
            color: #fff;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);

            i {
                color: inherit;
            }

            &.isLoading {
                background-image: none;
                background-color: #a5c9b7;
                border-color: #a5c9b7;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
            }

            &.isSaved {
                background-color: #3c7858;
                border-color: #3c7858;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                border-radius: 100px;
            }
        }
    }

    &:hover {
        &:disabled,
        &.disabled,
        &[aria-disabled='true'] {
            background-color: #6c6c6c;
            border-color: #6c6c6c;
            color: #fff;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);

            i {
                color: inherit;
            }

            &.isLoading {
                background-image: none;
                background-color: #a5c9b7;
                border-color: #a5c9b7;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
            }

            &.isSaved {
                background-color: #3c7858;
                border-color: #3c7858;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                border-radius: 100px;
            }
        }
    }

    &.leftContent {
        &:disabled,
        &.disabled,
        &[aria-disabled='true'] {
            background-color: transparent;
            border-color: transparent;
        }
    }
}

@keyframes enabledEffect {
    0% {
        color: #fff;
        border-color: #6c6c6c;
        background-position: 0%;
        background-image: -webkit-linear-gradient(30deg, #6c6c6c 50%, var(--button-bg) 50%);
        background-image: linear-gradient(30deg, #6c6c6c 50%, var(--button-bg) 50%);
    }

    50% {
        background-image: -webkit-linear-gradient(30deg, #6c6c6c 20%, var(--button-bg) 80%);
        background-image: linear-gradient(30deg, #6c6c6c 20%, var(--button-bg) 80%);
    }

    100% {
        background-position: 100%;
        color: var(--button-c);
        border-color: var(--button-border);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}

.fa,
.fab,
.fal,
.far,
.fas {
    vertical-align: middle;
    line-height: 1.2;
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    i,
    .material-symbols-outlined {
        font-size: 1rem;
        line-height: 1;

        &.fs-14px {
            font-size: 14px;
        }

        &.fs-22px {
            font-size: 22px;
        }
    }

    &.quantity-button {
        i {
            font-size: 0.766rem;
        }
    }
}

.fa-stack-2x {
    line-height: 1;
}

.btn-default {
    &.with-accommodations-icons {
        .fa-universal-access {
            color: #761fbb;
        }

        &:hover {
            .fa-universal-access {
                color: #fff;
            }
        }
    }

    &.modalSelections {
        &.activatedPrimary,
        &:hover {
            color: #0071be;
            border: 1px solid #0071be;

            .fa-play {
                visibility: visible;
            }
        }

        label {
            font-size: 1.333rem;
        }
    }

    &.self {
        border-color: #894812;
        color: #894812;

        &:hover {
            color: #894812;
            border: 1px solid #894812;
        }
    }
}

.primary-text {
    color: #0071be;
}

@media (max-width: 991px) {
    .btn {
        flex: 1;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

    .col-xs-12.btn {
        margin-left: 0;
        margin-top: 20px;
    }

    .col-xs-12.btn:first-child {
        margin-top: 0;
    }

    .creation .btn,
    .creationMethod .btn {
        width: 100%;
        margin-left: 0;
    }

    .creation .buttonGroup,
    .creationMethod .buttonGroup {
        display: block;
    }

    .creation .btn {
        margin-top: 20px;
    }

    .creation .btn:first-child,
    .creationMethod .btn:first-child {
        margin-top: 0;
    }

    .creation div:first-child > .btn,
    .creationMethod div:first-child > .btn {
        text-align: left;
        justify-content: left;
    }

    .creation div:first-child > .btn i:first-child,
    .creationMethod div:first-child > .btn i:first-child {
        font-size: 1.125em;
    }

    .creation div:first-child > .btn .fa-circle-o,
    .creationMethod div:first-child > .btn .fa-circle-o {
        color: #d8d8d8;
    }

    .tab-buttons {
        margin-top: 20px;
        .button {
            width: 50%;
        }
    }

    .btn-default:focus.resetBtn {
        background-color: #ac631b;
        color: #fff;
    }

    .btn-default:focus.deleteBtn {
        background: #ca3333;
        color: #fff;
    }
    .btn-default:focus.primaryHover {
        background: #0071be;
        color: #fff;
    }
}

@media (min-width: 992px) {
    .btn {
        flex: 1;
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }

    .btn.col-xs-12 {
        margin-left: 0;
        flex: 1;
    }

    .creation div:first-child > .btn i:nth-child(2),
    .creationMethod div:first-child > .btn i:nth-child(2) {
        font-size: 1em;
    }

    .tab-buttons {
        .button {
            width: 125px;
        }
    }
}

.sidebarHamburger {
    padding: 0;
}

.night-mode,
.dark-modal {
    .btn-success {
        --button-bg: #67b38b;
        --button-border: #67b38b;
        --button-c: #222;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: var(--button-c);

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #94caad;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #a5c9b7;
            border-color: #a5c9b7;
            color: var(--button-c);
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: var(--button-c);
        }

        &:active,
        &:active:focus {
            background-color: #94caad;
            border: 2px solid #94caad;
            color: var(--button-c);
        }
    }

    .btn-danger {
        --button-bg: #ff7777;
        --button-border: #ff7777;
        --button-c: #222;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: var(--button-c);

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #ff9292;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #e6aeae;
            border-color: #e6aeae;
            color: var(--button-c);
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: var(--button-c);
        }

        &:active,
        &:active:focus {
            background-color: #ff9292;
            border: 2px solid #ff9292;
            color: var(--button-c);
        }
    }

    .btn-primary,
    .btn-secondary {
        --button-bg: #3aafff;
        --button-border: #3aafff;
        --button-c: #222222;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: var(--button-c);

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #7ac9ff;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #93c4f9;
            border-color: #93c4f9;
            color: var(--button-c);
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: var(--button-c);
        }

        &:active,
        &:active:focus,
        &.active,
        &.active:focus {
            background-color: #7ac9ff;
            border: 2px solid #7ac9ff;
            color: var(--button-c);
        }
    }

    button {
        &:disabled,
        &.disabled,
        &[aria-disabled='true'] {
            background-color: #6c6c6c;
            border-color: #6c6c6c;
            color: #fff;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);

            i {
                color: inherit;
            }
        }

        &:focus-visible,
        &:focus,
        &:focus:hover {
            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background-color: #6c6c6c;
                border-color: #6c6c6c;
                color: #fff;
                box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);

                i {
                    color: inherit;
                }

                &.isLoading {
                    background-image: none;
                    background-color: #a5c9b7;
                    border-color: #a5c9b7;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                }

                &.isSaved {
                    background-color: #3c7858;
                    border-color: #3c7858;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                    border-radius: 100px;
                }
            }
        }

        &:hover {
            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background-color: #6c6c6c;
                border-color: #6c6c6c;
                color: #fff;
                box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);

                i {
                    color: inherit;
                }

                &.isLoading {
                    background-image: none;
                    background-color: #a5c9b7;
                    border-color: #a5c9b7;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                }

                &.isSaved {
                    background-color: #3c7858;
                    border-color: #3c7858;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                    border-radius: 100px;
                }
            }
        }
    }
}

label {
    font-size: 0.834rem;
    margin-bottom: 0;
}

input,
select {
    border-radius: 3px;
}

.form-horizontal {
    display: flex;
    align-items: center;
    vertical-align: center;
}

.form-horizontal .control-label {
    padding-top: 0;
}

select.form-control {
    background: linear-gradient(to bottom, #fff 0%, #f5f7f9 100%);
    box-shadow: none;
    border: solid 1px #717171;
    min-width: 150px;
    padding-right: 30px;
    line-height: 1.2;
    height: auto;
    width: auto;
    min-width: 100%;
    max-width: 100%;

    &:hover,
    &:focus,
    &:focus-visible {
        cursor: pointer;

        &.hasToolTip {
            pointer-events: inherit;
        }
    }

    &.disabled,
    &:disabled {
        background: #6c6c6c;
        border-color: #6c6c6c;
        color: #fff;
        cursor: not-allowed;

        &.hasToolTip {
            pointer-events: inherit;
        }

        &:after {
            color: #fff;
        }
    }
}

.has-feedback .form-control {
    padding-right: 15px;
}

.form-control-feedback {
    margin-top: 0;
    line-height: inherit;
    height: inherit;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.form-horizontal .control-label {
    color: #717171;
    padding-right: 0;
    font-weight: normal;
}

.form-control-static {
    width: 100%;
    padding: 0;
    min-height: auto;
    margin-bottom: 20px;
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.quantity input {
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    border: 1px solid #eee;
}

.quantity input:focus {
    outline: 0;
}

.container input:checked ~ .checkmark {
    background-color: #2196f3;
}

.eGalleryVote input[type='radio']:checked + span {
    color: #47a647;
}

textarea::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-webkit-input-placeholder,
textarea:-ms-input-placeholder {
    color: #767676;
    font-size: 1rem;
    font-weight: 100;
    font-style: italic;
}

.form-horizontal .control-label {
    line-height: 1;
    font-size: 1rem;
    color: #222;
    font-weight: bold;
}

input:checked + .slider {
    background-color: #761fbb;
}

input:focus + .slider {
    box-shadow: 0 0 1px #761fbb;
}

input[disabled] + .slider {
    background-color: #c5d0de;
}

input[type='number'].range {
    padding: 5px;
}

.radio-custom {
    input[type='checkbox'] {
        position: relative;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #717171;
        background: #fff;

        &:checked {
            border: solid 2px #3c7858;
            background: #3c7858;
            box-shadow: inset 0 0 0 2px #3c7858;

            &:before {
                font-family: FontAwesome;
                content: '\f00c';
                color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:hover {
            color: #0071be;
            border: solid 2px #0071be;
        }
    }
}

.checkbox-custom {
    input[type='checkbox'] {
        position: relative;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 20%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #cacaca;
        background: #fff;

        &:checked {
            border: solid 2px #3c7858;
            background: #3c7858;
            box-shadow: inset 0 0 0 2px #3c7858;

            &:before {
                font-family: FontAwesome;
                content: '\f00c';
                color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:hover {
            color: #0071be;
            border: solid 2px #0071be;
        }
    }
}

.qnsBuilderQnsOptions {
    input[type='radio'] {
        display: none;

        & + label:before {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            content: '';
            /* create custom radiobutton appearance */
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            padding: 3px;
            /* background-color only for content */
            background-clip: content-box;
            border: solid 1px #cacaca;
            background-color: #fff;
            border-radius: 50%;
        }

        &:checked {
            & + label:before {
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                padding: 3px;
                background-color: #47a647;
            }

            & + label {
                color: #47a647;
            }
        }
    }

    label {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;
    }
}

.quantity input {
    padding-left: 10px;
}

.description p span {
    font-size: 14px !important;
    font-weight: normal;
    line-height: 1.2;
}

/* Input checkbox */

/* End of Input Checkbox */

.mce-notification-warning {
    display: none !important;
}

.mce-notification-inner {
    display: none !important;
}
.mce-close {
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

.form-control {
    border: 1px solid #717171;
    line-height: 1;
    color: #222222;
    padding: 10px;
    font-size: 1rem;
    min-height: 36px;
    height: auto;
}

.form-control {
    resize: none !important;
}

p.form-control-static {
    font-size: 1rem;
    padding: 0;
    display: flex;
}

.control-label {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 5px;
    color: #3c3c43;
}

.col-form-label {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
}

.form-group {
    margin-bottom: 0;
}

.form-date {
    display: inline-block;
    width: 100%;
    position: relative;
}

.form-date .form-control {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
}

.form-date.viewFilter {
    margin-left: 10px;
}

.form-date:after {
    content: '\f073';
    font-family: 'FontAwesome';
    font-size: 1rem;
    color: #717171;
    right: 12px;
    top: 7px;
    position: absolute;
    pointer-events: none;
}

.help-block {
    min-height: 30px;
    margin: 0;
    padding: 5px 0;

    &:first-letter {
        text-transform: uppercase;
    }
}
.form-group {
    width: 100%;
}

.input-group.date .input-group-addon {
    border: none;
    color: #717171;
    background-color: transparent;
    width: auto;
    display: flex;
    align-items: center;
}

.input-group-addon {
    color: #222;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #717171;
}

.input-group.date .form-control {
    border: none;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    box-shadow: none;
    color: #222;
    font-weight: normal;
}

.input-group.date {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    display: flex;
}

.input-group.currency input {
    border-top-left-radius: 1px !important;
    border-bottom-left-radius: 1px !important;
    border-left: none;
}

.input-group.currency select {
    padding-right: 10px;
}

/* form-dropdown */

.form-dropdown select,
.formDropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

label.form-dropdown {
    position: relative;
}

.form-dropdown {
    width: 100%;
    position: relative;
}

.form-dropdown.viewFilter {
    margin-left: 10px;
}

.form-dropdown.viewFilter.marginLeft0 {
    margin-left: 0;
}

.form-dropdown.digit {
    display: inline-block;
    min-width: 70px;
    margin-left: 10px;
    max-width: 70px;

    &.marginLeft0 {
        margin-left: 0;
    }
}

.formDropdown {
    position: relative;
}

.form-dropdown,
.formDropdown {
    &:after {
        content: '\f0d7';
        font-family: 'FontAwesome';
        font-size: 1rem;
        color: #3c3c43;
        right: 12px;
        top: 24%;
        position: absolute;
        pointer-events: none;
        line-height: 1.2;
    }

    &.disabled {
        &:after {
            color: #fff;
        }
    }
}

.input-group.currency .form-dropdown {
    min-width: auto;
}

.input-group.currency .form-dropdown select {
    border: none;
}

.input-group.currency .form-dropdown:after {
    right: 0;
    top: 0;
}

.calendar-time select {
    height: 28px;
}

.daterangepicker .calendar-time {
    line-height: 1;
}

/* end of form-dropdown */

.form-control {
    &:focus {
        border: 1px solid #0071be;
        outline: none;
    }

    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }
}

@media screen and (max-width: 767px) {
    .table-responsive {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .form-date {
        width: 200px;
    }

    .periodBars .form-date {
        margin-left: 20px;
    }

    .periodBars .form-date:first-child {
        margin-left: 10px;
    }

    .max90px {
        max-width: 90px;
        width: 90px;
    }

    .width300px {
        width: 300px;
    }
}
@media (max-width: 991px) {
    .form-dropdown.viewFilter {
        margin-left: 28px;
    }
    .form-date.viewFilter {
        margin-left: 25px;
    }

    .subBar .periodBars {
        display: block;
    }

    .periodBars .form-date {
        width: 100%;
    }

    .periodBars .form-date {
        margin-left: 0;
        margin-top: 10px;
    }

    .max60px {
        max-width: 60px;
        width: 60px;
    }

    .width280px {
        width: 280px;
    }
}

.form-control[disabled],
.form-control[readonly] {
    background-color: #efefef;
    border: 1px solid #6c6c6c;
    color: #354052;
    cursor: not-allowed;

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
        color: #6c6c6c;
    }
}

.toggle-display label div {
    margin-top: 4px;
}

.toggleLabelWidth {
    width: calc(100% - 74px);
}

.customNumberInput {
    position: relative;

    &.timeLimit {
        width: 58px;
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        max-height: 100%;
    }

    .quantity-button {
        min-height: 18px;
        height: 50%;
        display: flex;
        align-items: center;
        width: 22px;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #717171;
        border-radius: 0;

        &:first-child {
            border-top-right-radius: 8px;
        }

        &:last-child {
            border-bottom-right-radius: 8px;
            border-top: 1px solid #717171;
        }

        i {
            font-size: 0.8rem;
        }

        &:disabled,
        &[aria-disabled='true'] {
            background: #efefef;
            color: #6b6b6f;
            cursor: not-allowed;
            box-shadow: -1px -1px 2px 0 rgba(202, 202, 202, 0.5);
        }
    }
}

td span {
    line-height: 1.2;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    -moz-border-radius: 3px;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
}

.table-responsive {
    width: 100%;
}

.table > thead > tr > th {
    border-bottom: none;
    vertical-align: top;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 10px 20px;
}

.x_content .table:last-child,
.table:last-child {
    margin-bottom: 0;
}

table th,
table td {
    padding: 15px 20px;
    border-right: solid 1px #d8d8d8;
    font-size: 1rem;
}

table th:last-child,
table td:last-child {
    border-right: none;
}

thead tr:first-child {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);

    th {
        font-size: 0.88rem;
        font-weight: bold;
    }
}

thead tr:last-child {
    border-radius: 3px;
    background-color: #f6f8fa;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-top: solid 1px #d8d8d8;
    border-bottom: solid 1px #d8d8d8;
    background-image: none;

    th {
        font-size: 1rem;
        font-weight: bold;
    }
}

thead tr:only-child {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    text-transform: none;
    border-bottom: 0;
    th {
        font-size: 0.88rem;
    }

    border-top: 0;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 15px 20px;
    line-height: 1.2;

    &.py-9px {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}

table .numColumn {
    width: 1%;
}

.creation table .content {
    padding: 20px;
}

.subContent {
    padding: 20px;
    background: #fafafa;
}

.creation .uploadDiv {
    border: 1px dashed #989b9c;
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creation .uploadDiv div {
    text-align: center;
}

.creation .uploadDiv div i {
    color: #717171;
    font-size: 30px;
}

.creation .uploadDiv .btn {
    margin-left: 0;
    margin-top: 10px;
}

table td.borderNone,
.table > tbody > tr > td.borderNone,
.table > thead > tr > th.borderNone,
.table > thead > tr.borderNone {
    border: none;
}

table td.borderNone,
.table > tbody > tr > td.borderTop1pxGrey {
    border-top: 1px solid #d8d8d8;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        border: none;
        overflow: inherit;

        &.overflowAuto {
            overflow: auto;
        }
    }

    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > tfoot > tr > td {
        white-space: normal;

        &.whiteSpaceBreakSpacesText {
            white-space: break-spaces;
        }
    }

    .table-responsive > .table > thead > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > thead > tr > td.whiteSpaceNowrap,
    .table-responsive > .table > tbody > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > tbody > tr > td.whiteSpaceNowrap,
    .table-responsive > .table > tfoot > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > tfoot > tr > td.whiteSpaceNowrap {
        white-space: nowrap;
    }

    .table-responsive.creationMethod {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .table-responsive.creation {
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .table-responsive.creation {
        margin-bottom: 30px;
    }

    .creation .uploadDiv .btn {
        margin-top: 10px;
    }

    .creation div:first-child > .uploadDiv .btn {
        text-align: center;
    }
}

th {
    white-space: nowrap;
}

.course-table-responsive,
.module-activities-view {
    .table-responsive {
        overflow-x: inherit;
    }
}

thead {
    tr:last-child th {
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 0.88rem;
    }

    &.subContent-header {
        tr th {
            padding: 15px 20px;
            font-size: 1rem;
        }
    }
}

.isStudent {
    table,
    .table,
    .subTable {
        font-size: 1em;

        thead {
            tr {
                &:first-child {
                    th {
                        font-size: 1.333rem;

                        span {
                            font-size: 1.112rem;
                        }
                    }
                }

                &:last-child {
                    th {
                        font-size: 0.8888rem;
                    }
                }
            }
        }

        td {
            font-size: 1rem;
        }
    }
}

.overflowXinherit {
    .table-responsive {
        overflow-x: inherit;
    }
}

caption {
    font-weight: bold;
    color: #222;
    font-size: 1rem;
}

.tableBodyFixedHeight-attendance {
    width: 100%;

    .attendance-new-table {
        border: none;
        height: auto;
        min-height: 455px;
        display: inherit;
        box-shadow: none;

        .subHeader {
            padding: 10px 20px;
            color: #222;
            background: #efefef;
        }

        .leftRightWidth {
            width: 50%;
            max-width: 50%;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;

        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 9;
            }
        }

        tbody {
            tr {
                th,
                td {
                    padding: 8px 20px;
                    border: 0;
                }
            }
        }

        &.table {
            & > thead {
                & > tr {
                    & > th {
                        background: #fff;
                        border: 0;
                    }
                }
            }
        }
    }
}
.tableBodyFixedHeight-lti-course-list {
    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
        max-height: 400px;
        overflow-y: auto;
    }

    .selectAllFixedWidth {
        width: 138px;
        max-width: 138px;
    }
}

.table-layout-fixed {
    table-layout: fixed;
}

#evaIndividual,
#evaOnTeam,
#evaOnInstructor,
#michelsenMethod {
    table {
        td:last-child {
            border-right: solid 1px #d8d8d8;
        }

        .thisRow {
            td {
                &:last-child {
                    border-right: none;
                }

                &.disabled {
                    background-color: #c5d0de;
                }
            }
        }
    }
}

.newEvaluationTable {
    .width150px {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
    }

    table {
        border: 0;
        border-collapse: inherit;

        thead {
            tr {
                border: none;
                box-shadow: none;

                &:first-child {
                    th:last-child {
                        border-right: none;
                    }
                }
            }

            th {
                border: solid 1px #d8d8d8;
                box-shadow: none;
                border-left: none;
                font-size: 14px;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 14px;
                padding-right: 14px;
            }

            th[rowspan] {
                background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
            }

            tr {
                &:nth-child(2) {
                    text-transform: none;
                    background: #fff;
                }

                &:first-child {
                    text-transform: uppercase;
                }
            }
        }

        th,
        td {
            white-space: normal;

            &.whiteSpaceNowrap {
                white-space: nowrap;
            }

            &.whiteSpacePreWrap {
                white-space: pre-wrap;
            }

            &.tableHeaderMaxWidth400px {
                max-width: 400px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        th {
            border: 1px solid #efefef;
        }

        td {
            border: 1px solid #d8d8d8;
            border-left: 0;

            &.disabled {
                background-color: #c5d0de;
            }
        }

        .notFirstRow {
            td:last-child {
                border-right: solid 1px #d8d8d8;
            }
        }

        tbody {
            td {
                padding: 14px;
            }
        }
    }

    .sub-table {
        padding: 8px;

        table {
            width: 100%;
            border: solid 1px #d8d8d8;
            box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);

            thead {
                tr {
                    &:first-child {
                        background-image: none;
                        background: #ebf3fe;
                    }

                    &:last-child {
                        background: #23356d;
                        color: #fff;
                    }
                }
            }

            tbody {
                td {
                    &.disabled {
                        background: #e4e4e4;
                        color: #656569;
                    }
                }
            }
        }
    }

    .light-blue-bg {
        background-image: none;
        background: #2b5ba3;
        color: #fff;
    }

    .light-orange-bg {
        background: #fdefd8;
    }
}

.aggregatedScoreTable {
    table {
        border-collapse: separate;

        thead {
            tr:first-child {
                th {
                    &:nth-child(-n + 2),
                    &:nth-last-child(-n + 6) {
                        position: sticky;
                        z-index: 1;
                    }

                    &:first-child {
                        left: 0;
                    }

                    &:nth-child(2) {
                        left: 150px;
                        box-shadow: 5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }

                    &:last-child {
                        right: 0;
                        border-right: 1px solid #d8d8d8;
                    }

                    &:nth-last-child(2) {
                        right: 80px;
                    }

                    &:nth-last-child(3) {
                        right: 80px * 2;
                    }

                    &:nth-last-child(4) {
                        right: 80px * 3 + 28px;
                    }

                    &:nth-last-child(5) {
                        right: 80px * 4 + 28px + 5px;
                    }

                    &:nth-last-child(6) {
                        right: 80px * 5 + 28px + 10px;
                        box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }
                }
            }

            th {
                border-top: 0;
                border-left: 0;
            }
        }
        tbody {
            tr {
                td {
                    background: #fff;

                    &:nth-child(-n + 2) {
                        position: sticky;
                        z-index: 1;
                    }

                    &:first-child {
                        left: 0;
                    }

                    &:nth-child(2) {
                        left: 150px;
                        box-shadow: 5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }

                    border-top: 0;
                    border-left: 0;

                    &.disabled {
                        background: #e4e4e4;
                        color: #8b8b8f;
                    }
                }

                &.isFirstRow {
                    td {
                        &:nth-last-child(-n + 6) {
                            position: sticky;
                            z-index: 1;
                        }

                        &:last-child {
                            right: 0;
                        }

                        &:nth-last-child(2) {
                            right: 80px;
                        }

                        &:nth-last-child(3) {
                            right: 80px * 2;
                        }

                        &:nth-last-child(4) {
                            right: 80px * 3 + 28px;
                        }

                        &:nth-last-child(5) {
                            right: 80px * 4 + 28px + 5px;
                        }

                        &:nth-last-child(6) {
                            right: 80px * 5 + 28px + 10px;
                            box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                        }
                    }
                }

                &.notFirstRow {
                    td {
                        &:nth-last-child(-n + 5) {
                            position: sticky;
                            z-index: 1;
                        }

                        &:last-child {
                            right: 0;
                        }

                        &:nth-last-child(2) {
                            right: 80px;
                        }

                        &:nth-last-child(3) {
                            right: 80px * 2;
                        }

                        &:nth-last-child(4) {
                            right: 80px * 3 + 28px;
                        }

                        &:nth-last-child(5) {
                            right: 80px * 5 + 28px + 10px;
                            box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                        }
                    }
                }
            }
        }
    }
}

.individualTableV2 {
    border: 1px solid #d8d8d8;

    table {
        thead {
            tr:first-child {
                & > th {
                    &:nth-child(-n + 2),
                    &:nth-last-child(-n + 3) {
                        position: sticky;
                        z-index: 1;
                    }

                    &:first-child {
                        left: 0;
                    }

                    &:nth-child(2) {
                        left: 150px;
                        box-shadow: 5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }

                    &:nth-last-child(-n + 3) {
                        min-width: 80px;
                        max-width: 80px;
                        width: 80px;
                    }

                    &:last-child {
                        right: 0;
                    }

                    &:nth-last-child(2) {
                        right: 80px;
                    }

                    &:nth-last-child(3) {
                        right: 80px * 2;
                        box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }
                }
            }

            th {
                border-left: 0;

                &.withDescription {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                }
            }

            tr:not(:first-child) {
                th {
                    border-top: 0;
                }
            }
        }

        tbody {
            tr {
                td {
                    &:nth-child(-n + 2) {
                        position: sticky;
                        z-index: 1;
                        background: #fff;
                    }

                    &:first-child {
                        left: 0;
                    }

                    &:nth-child(2) {
                        left: 150px;
                        box-shadow: 5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                    }

                    border-top: 0;
                    border-left: 0;
                }

                &.isFirstRow {
                    td {
                        &:nth-last-child(-n + 3) {
                            position: sticky;
                            z-index: 1;
                            background: #fff;
                        }

                        &:last-child {
                            right: 0;
                            border-right: 0;
                            border-bottom: 0;
                        }

                        &:nth-last-child(2) {
                            right: 80px;
                        }

                        &:nth-last-child(3) {
                            right: 80px * 2;
                            box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                        }
                    }
                }

                &.notFirstRow {
                    td {
                        &:nth-last-child(-n + 2) {
                            position: sticky;
                            z-index: 1;
                            background: #fff;
                        }

                        &:last-child {
                            right: 80px;
                        }

                        &:nth-last-child(2) {
                            right: 80px * 2;
                            box-shadow: -5px 0px 10px 1px rgba(202, 202, 202, 0.6);
                        }
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.verticalTableHeader {
    writing-mode: vertical-rl;
    max-height: 100px;
}
